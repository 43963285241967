import React from 'react';

export const Budapest = {
    "name": "Budapest",
    "link": "budapest",
    "country": "Hungary",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50380492857_f769b7a167_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380492857_405979d358_o.jpg",
    "caption": "The two cities capital",

    "description": "One of the most beautiful and attractive cities in all of Central Europe, Budapest has a range of incredible things to visit and explore. From the Romans and Turks to the Austro-Hungarians and Soviets, Budapest was influenced in such a way that it created a variety of different cultures, which turned the city into a fascinating place to visit. Situated along the sides of the Danube river, the capital of Hungary used to be divided into two medieval cities, Buda and Pest. However, back in 1873 they merged into one city – Budapest.",

    "nrDays":<p>
            Budapest is a huge city and it has a lot of attractions a little far from each other. We recommend <b>3 days</b> in the city in order to be possible to visit and experiment all the main spots, and to enjoy the night life that this city has to offer.
            If you want to visit also the countryside you can use one of the days to make a day trip to <b>Szentendre and Gödöllő Royal Palace</b> or <b>Visegrád</b>.
        </p>,

    "whenVisit": <p>
            Summer is the high season. It's at this time when the city is full of tourists. Personally we recommend to avoid the months
            of July and August and try to visit the city at the <b>end of May/early June and September</b>. It's still good weather and less people.
            <br/><br/>
            There is one very famous festival happening on August in town, the <b>Sziget Festival</b>. If you fancy music festivals, this is one you should not miss.
            <br/><br/>
            On the other side, <b>November to December</b> are fantastic months to visit for the Christmas markets, and several events and festivals like the Magic Bath Parties, International Guitar Festival, Total Dance Festival, Nutcracker Festival, and New Year Bath Party,
            occurs during this period.
            </p>,

    "mobility": <p>
        The city bus network in Budapest has over 200 routes. Additionally, Budapest has a large network of yellow streetcars/trams
        that covers all the city. There is a very modern metro system running along 4 lines too.
        Tickets are valid on the bus, metro, trams, and trolley buses, and can be purchased at any metro station with a single ride costing
        about 350 HUF (1€). You can also get the tickets at most newsstands, street stands, and ticket vending machines. One standard
        ticket is good for one trip. That means if you need to transfer, you will need a new one unless you have bought a transfer
         ticket. Don't forget to <b>validate the ticket</b> every time you switch tram/bus.
    </p>,

    "safety": <p>
    Most visitors to the country don't experience any kind of difficulties, but as in all big cities, you should be always aware of
    street crime and petty theft. Always exchange currency at a currency exchange office or bank, never on the street as this
    money is sometimes counterfeit. You can use Revolut in most of places too.
    </p>,

    "itinerary": {
        "description": "Budapest has a lot of good places to visit and most of them are relatively away from each other. We will suggest here a 3-day itinerary in which the last day could be used for a day trip or to visit more of the city.",
        "days": [
            {
                "description": <p>
                    For the first day we suggest you start with a <b>free walking tour</b>. It is a good experience
                    and you will learn a little more about the city's culture and history.
                    This tour will guide you all over the major points, such as the <b>St. Stephen’s Basilica, Shoes on the Danube Promenade, Jewish Quarter, Buda Castle and Fisherman’s Bastion.</b> After the tour, ask your
                    guide a good place to taste traditional food. After lunch you can explore a little more depth of the Castles (and the museums if it is on your interests), the Fisherman's
                    Bastion and the Matthias Church.
                    <br/><br/>
                    At the evening see the sunset from the <b>Citadel</b> (also a place with big night life, where the people meet to talk and drink) and get a nice plan fir a relatively early dinner. At night enjoy the view from the Danube in one of the many <b>cruises available</b>.
                    You will have an amazing view to the <b>Parliament</b> here.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50497799013_9f9b30bc48_o.jpg",
                "caption": "View of Pest from a spot close to the Citadel."
            },
            {
                "description": <p>
                        For the second day, start with a visit to the <b>Great Market Hall</b>. Get some food here to have at lunch and if possible something to eat fast.
                        Before lunch visit the <b>Parliament</b>. After that take some snacks and go for a quick ride to the <b>Heroe's Square</b>, and be amazed by all the statues and
                        structures there. To finish your afternoon, take some hours to relax at the <b>Széchenyi Thermal Bath</b>, the most famous thermal baths of the city.
                        <br/><br/>
                        After a quick stop at home to get a shower and leave your stuffs, find a nice place to taste the traditional cuisine, we recommend the Frici Papa Kifozdeje Restaurant at the Pest side, and be ready for an amazing night at <b>Szimpla Kert</b>, the famous ruin bar.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50498513071_189fa79202_o.jpg",
                "caption": "Széchenyi Thermal Bath outside pool."
            },
            {
                "description": <p>
                    For your last day you have two options. Or make a day trip to either the <b>Szentendre and Gödöllő Royal Palace</b> or <b>Visegrád</b>, or if you are
                    too tired from the previous days and want to relax, go to the <b>Margaret Island</b> and find more hidden spots in the streets of Pest.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498662732_977bcf69ac_o.jpg",
                "caption": "Gödöllő Royal Palace."
            }
        ]
    },

    "highlights": [
        {
            "name": "Hungarian Parliament Building",
            "description": <p>
                The Hungarian Parliament building (Országház) is the city’s most unique and known landmark. Close to the Danube, this
                 building in the Gothic Revival style is the third largest Parliament building in the world. You can visit the interior
                 by around 13€ (<a href="https://www.tiqets.com/en/budapest-attractions-c68199/tickets-for-hungarian-parliament-exclusive-guided-visit-p974946/?partner=myvacation&tq_campaign=bud_it3" target="_blank" rel="noopener noreferrer">tickets here</a>),
                 and you will be able to see the main entrance stairs and hall, one of the lobbies, the House of Lords and the Hungarian Crown Jewels.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497798933_51a3b2c4d3_o.jpg"
        },
        {
            "name": "St. Stephen’s Basilica",
            "description": <p>
                St. Stephen’s Basilica is the largest church in Budapest. Szent István-bazilika is dedicated to Saint
                Stephen I, the first King of Hungary. You can see his mummified right hand in a glass case in the chapel.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498662702_6f4e3ef652_o.jpg"
        },
        {
            "name": "Shoes on the Danube Promenade",
            "description": <p>Shoes on the Danube Promenade is a memorial close to the river, dedicated to the murdered Jews by the fascist Arrow Cross militiamen.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498662667_045f4e0c6f_o.jpg"
        },
        {
            "name": "Széchenyi Thermal Bath",
            "description": <p>There are several baths in Budapest to check out but the most famous one is the Széchenyi Thermal Bath. With an entrance fee of
                around 18€ you will have time to enjoy the different waters there. Sometimes it has night parties too. </p>,
            "image": "https://live.staticflickr.com/65535/50498513001_4a5cc26a60_o.jpg"
        },
        {
            "name": "Great Market Hall",
            "description": <p>
                You will find this market at a beautiful building with a lively atmosphere. It’s always fun wandering around the bustling market and
                try the different specialities from the local cuisine.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498662647_b61e0d1716_o.jpg"
        },
        {
            "name": "Jewish Quarter",
            "description": <p>With numerous monuments and quirky coffee shops to discover, the narrow streets make for delightful exploring. Take a time to
                know more about the tragic history that this community suffered in the country.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497798888_96ef36d207_o.jpg"
        },
        {
            "name": "Szimpla Kert",
            "description": <p>One of the most famous bars of Central Europe. Maybe the first ruin bar, it is known by its distinctive and unique artistic style
                like no other. This place really comes alive at night.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498662622_ece7090874_o.jpg"
        },
        {
            "name": "Heroe’s Square",
            "description": <p>Heroe's Square is a pretty cool monument that has statues of a number of famous Hungarian national leaders including the Seven Chieftains of the Magyars.</p>,
            "image": "https://live.staticflickr.com/65535/50497798853_b6f0ff1c75_o.jpg"
        },
        {
            "name": "Fisherman’s Bastion",
            "description": <p>Fisherman’s Bastion (Halászbástya) looks like it came out from a fairytale. Its seven towers symbolize the seven Magyar tribes that settled on 896 in this area.</p>,
            "image": "https://live.staticflickr.com/65535/50498512936_0675759bca_o.jpg"
        },
        {
            "name": "Matthias Church",
            "description": <p>It was named after King Matthias reign. Its colourful diamond shape roof and white stone in Gothic style makes the Mátyás-templom a good place to visit. </p>,
            "image": "https://live.staticflickr.com/65535/50498662542_b94487df39_o.jpg"
        },
        {
            "name": "Buda Castle",
            "description": <p>Buda Castle with its marvellous architecture and breathtaking views over Pest is a must. However, nowadays
                the castle interior is a complex of museums. <b>The Budapest History Museum</b> (entrace fee around 7€) and the <b>Hungarian National Gallery</b> (entrance fee around 7€ also) are part of this complex.</p>,
            "image": "https://live.staticflickr.com/65535/50498512916_a530685858_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            One important thing in Budapest is to taste the local cuisine! You can try to get some of the local specialities at the Great Market Hall or prove the local dishes in a good restaurant.
            <br/><br/>
            For a cheap meal you can always look for a good restaurant in <a href="https://www.thefork.com/" target="_blank" rel="noopener noreferrer"> The Fork. </a>If you are searching for the best dishes, we give you an idea of what to sample.
            </p>,

        "restaurants": [
            {
                "name": "Frici Papa Kifozdeje",
                "description": "Very famous between the locals, this cheap and simple place is one of the best places to eat if you want to taste Hungarian food.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497798793_f030ec6daf_o.jpg"
            },
            {
                "name": "Parisi 6",
                "description": "With great food and friendly service this budget place will leave you satisfied.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50497798773_91ae7b68da_o.jpg"
            },
            {
                "name": "La Botte",
                "description": "A nice Italian restaurant in the streets of Budapest. The pizza and the pasta are two good choices here.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50497798758_1504799f62_o.jpg"
            },
            {
                "name": "Lucky 7 Burgers & More",
                "description": "Great service and great burgers! Get the union rings, those are really good.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498662462_d9fe5a2210_o.jpg"
            },
            {
                "name": "Paprika",
                "description": "The Fish Soup, The Lamb and The Goose leg are two of the specialities. One of the best to taste local cuisine.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498662452_3b667abeb4_o.jpg"
            },
            {
                "name": "Hungarikum Bisztro",
                "description": "A charming, little restaurant with authentic Hungarian food, drinks and atmosphere.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498512781_3857caec79_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
               Budapest has a large choice of accommodations, from hostels to five star hotels. As a big tourist city you will not have
               any difficulties to find out the place that is more comfortable for your stay.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "Jackquaters",
                "description": "Located right next to Vaci Utca and only 150 meters from the Danube, Jackquaters is the perfect location for anyone visiting Budapest.",
                "image": "https://live.staticflickr.com/65535/50498662427_51ee955ecf_o.jpg",
                "link": "/"
            },
            {
                "name": "The Hive Party Hostel Budapest",
                "description": "A perfect location and environment for a trip with your friends.",
                "image": "https://live.staticflickr.com/65535/50498512731_1335e98db3_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Danubius Hotel Erzsébet City Center",
                "description": "Located on a pedestrian street with cafés and bars, Hotel Erzsebet City Center is only a few steps from the Vaci utca shopping street and the Great Market Hall. ",
                "image": "https://live.staticflickr.com/65535/50497798628_ae2a3e50bd_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Hotel Mika Downtown",
                "description": "Hotel Mika is located in the historical Jewish quarter in the centre of Budapest, 450m from the State Opera House. A perfect location with amazing conditions.",
                "image": "https://live.staticflickr.com/65535/50498662357_2e30883b44_o.jpg",
                "link": "/"
            }
        ]
    }
}
