import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Budapest } from '../../../guides/countries/hungary/budapest';

function BudapestPage(props) {
    return (
        <div>
            <CityGuide city={ Budapest } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default BudapestPage;
